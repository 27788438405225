import * as THREE from 'three';
import { OrbitControls, TessellateModifier, ThreeMFLoader } from 'three/examples/jsm/Addons.js';
import brickTexture from '../Texture/brick.jpg';
import woodTexture from '../Texture/wood.jpg';
import floorTexture from '../Texture/floor.jpg';
import concreteTexture from '../Texture/concrete.jpg'
import front from '../Texture/front.jpg';
import left from '../Texture/right.jpg';
import right from '../Texture/left2.jpg';
import bannerTexture from '../Texture/logo2.png';
import banner2Texture from '../Texture/logowhite.png';
import acubiqBannerTexture from '../Texture/ACUBIQ.jpg';
//import * as dat from 'dat.gui';
//import * as TX from '../scripts/Textures.js'
import { GLTFLoader } from 'three/examples/jsm/Addons.js';
const cactusURL = new URL('../model/magic_laboratory.glb',import.meta.url);



//Texture
const textureLoader = new THREE.TextureLoader();
//---------------------------------------------------( RENDERER )--------------------------------------------------
// Renderer
const renderer = new THREE.WebGLRenderer( );
//Activate shadows or enable renderer to show shadows
renderer.shadowMap.enabled = true;
renderer.setClearColor(0x000000);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.setPixelRatio);
document.body.appendChild(renderer.domElement);



//------------------------------------------( SCENE & CAMERA )---------------------------------------------------------
//Scene
const scene = new THREE.Scene();
//Camera
const camera = new THREE.PerspectiveCamera(
    45,//FOV
    window.innerWidth/window.innerHeight,//Aspect ratio
    0.1,//Near plane
    1000//Far plane
);
//(0,18,45) origina position
camera.position.set(-18,30,45);
//camera.position.set(0,50,0);  
  
// Adding orbit controls 
const orbit = new OrbitControls(camera, renderer.domElement);
orbit.update();
orbit.enableRotate=true;
orbit.enablePan=true;
orbit.enableZoom=true;
//orbit.maxDistance=150;




//Materials ----------------------------------( MATERIALS )-----------------------------------------------------
// wood material
const woodMaterial = new THREE.MeshStandardMaterial(
    {   color:0x777777,
        map: textureLoader.load(woodTexture)
    }
);
//Brick material
const brickMaterial = new THREE.MeshStandardMaterial(
    {  color:0xaaaaaa,
        map: textureLoader.load(brickTexture)
    }
);

//Concrete material
const concreteMaterial = new THREE.MeshStandardMaterial(
    {  color:0xcccccc,
        map: textureLoader.load(concreteTexture)
    }
);
//Front material
const frontMaterial = new THREE.MeshStandardMaterial(
    {
        map:textureLoader.load(front)
    }
);
//Left material
const leftMaterial = new THREE.MeshStandardMaterial(
    {
        map:textureLoader.load(left)
    }
);
//Right material
const rightMaterial = new THREE.MeshStandardMaterial(
    {
        map:textureLoader.load(right)
    }
);
//wolfies material
const wolfiesMaterial = new THREE.MeshStandardMaterial(
    {
        map:textureLoader.load(bannerTexture)
    }
);
//bModulo material
const ModuloMaterial = new THREE.MeshStandardMaterial(
    {
        map:textureLoader.load(banner2Texture)
    }
);
//acubiqBannerTexture
const acubiqBannerMaterial = new THREE.MeshStandardMaterial(
    {
        map:textureLoader.load(acubiqBannerTexture)
    }
);
//---------------------------------------------------( OBJECTS )-----------------------------------------------

// Cube
const cubeGeometry = new THREE.BoxGeometry(8,8,8);
const cubeMaterial = new THREE.MeshStandardMaterial({
    
});
const cube = new THREE.Mesh(cubeGeometry,cubeMaterial);
cube.position.set(0,4,0);
cube.castShadow=true;
scene.add(cube);

//Cube 2
const cube2Geometry = new THREE.SphereGeometry(2);
const cube2Material = new THREE.MeshStandardMaterial({
    //color:0x000000
});
const cube2 = new THREE.Mesh(cube2Geometry,cube2Material);
cube2.position.set(0,2,25);
cube2.castShadow=true;
scene.add(cube2);
cube2.material = concreteMaterial;

//Cube3
const cube3Geometry = new THREE.SphereGeometry(1.8);
const cube3Material = new THREE.MeshStandardMaterial({
    //color:0x000000
});
const cube3 = new THREE.Mesh(cube3Geometry,cube3Material);
cube3.position.set(5,2,25);
cube3.castShadow=true;
scene.add(cube3);
cube3.material=woodMaterial;

//Cube4
const cube4Geometry = new THREE.SphereGeometry(1.8);
const cube4Material = new THREE.MeshStandardMaterial({
    ///color:0x000000
});
const cube4 = new THREE.Mesh(cube4Geometry,cube4Material);
cube4.position.set(-5,2,25);
cube4.castShadow=true;
scene.add(cube4);
cube4.material=brickMaterial;

//Donut
const donutGeometry = new THREE.TorusGeometry(2,1);
const donutMaterial = new THREE.MeshStandardMaterial({
    color:0x666666
});
const donut = new THREE.Mesh(donutGeometry,donutMaterial);
donut.position.set(12,3,15);
donut.castShadow=true;
scene.add(donut);

//Donut 2
const donut2Geometry = new THREE.TorusGeometry(2,1);
const donut2Material = new THREE.MeshStandardMaterial({
    color:0x666666
});
const donut2 = new THREE.Mesh(donut2Geometry,donut2Material);
donut2.position.set(-12,3,15);
donut2.castShadow=true;
scene.add(donut2);



// Temporary shapes
const demoCylinderGeometry = new THREE.CylinderGeometry(5,5,20);
const sphereDemoGeometry = new THREE.SphereGeometry(4);
const boxDemoGeometry = new THREE.BoxGeometry(8,8,8);


// Sphere
const sphereGeometry = new THREE.SphereGeometry(4);
const sphereMaterial = new THREE.MeshStandardMaterial({color:0x444444});
const sphere = new THREE.Mesh(sphereGeometry,sphereMaterial);
scene.add(sphere);
sphere.castShadow=true;
sphere.receiveShadow=true;
sphere.position.set(40,4,5);
sphere.material=woodMaterial;
sphere.name='sphere';

//Cylinder
const cylinderGeometry = new THREE.CylinderGeometry(3,3,10);
const cylinderMaterial = new THREE.MeshStandardMaterial({color:0x444444});
const cylinder = new THREE.Mesh(cylinderGeometry,cylinderMaterial);
scene.add(cylinder);
cylinder.castShadow=true;
cylinder.receiveShadow=true;
cylinder.position.set(40,5,-7);
cylinder.material=woodMaterial
cylinder.name = 'cylinder';

//Box
const boxGeo = new THREE.BoxGeometry(8,8,8);
const boxMat = new THREE.MeshStandardMaterial({color:0x444444});
const box = new THREE.Mesh(boxGeo,boxMat);
box.material=woodMaterial
scene.add(box);
box.receiveShadow=true;box.castShadow=true;
box.position.set(40,4,-20);
box.name='box';

//Banner
const moduloBannerGeom = new THREE.PlaneGeometry(35,20);
const moduloBannerMat = new THREE.MeshStandardMaterial();
const moduloBanner = new THREE.Mesh(moduloBannerGeom,moduloBannerMat);
scene.add(moduloBanner);
moduloBanner.material=ModuloMaterial;
moduloBanner.position.set(0,25,-49);

//Banner
const wolfieBannerGeom = new THREE.PlaneGeometry(35,20);
const wolfieBannerMat = new THREE.MeshStandardMaterial();
const wolfieBanner= new THREE.Mesh(wolfieBannerGeom,wolfieBannerMat);
scene.add(wolfieBanner);
wolfieBanner.rotation.y = Math.PI;
wolfieBanner.material=wolfiesMaterial;
wolfieBanner.position.set(0,25,49);

//acubiqBannerMaterial
const acubiqBannerGeom = new THREE.PlaneGeometry(20,20);
const acubiqBannerMat = new THREE.MeshStandardMaterial();
const acubiqBanner= new THREE.Mesh(acubiqBannerGeom,acubiqBannerMat);
scene.add(acubiqBanner);
acubiqBanner.rotation.y = Math.PI;
acubiqBanner.material=acubiqBannerMaterial;
acubiqBanner.position.set(49,25,0);
acubiqBanner.rotation.y = -0.5*Math.PI;

//-------------------------------------( PLANES )------------------------------------------------------------
//Floor
const floorGeometry = new THREE.PlaneGeometry(100,100);
const floorMaterial = new THREE.MeshStandardMaterial(
                                {color:0x777777,
                                 side:THREE.DoubleSide,
                                 map: textureLoader.load(floorTexture)
                                });
const floor = new THREE.Mesh(floorGeometry, floorMaterial);
floor.rotation.x = -0.5 *Math.PI;
floor.receiveShadow = true;
scene.add(floor);

//Front wall
const frontWallGeometry = new THREE.PlaneGeometry(100,50);
const frontWallMaterial = new THREE.MeshStandardMaterial(
                                {color:0x888888,
                                 side:THREE.DoubleSide,
                                 map:textureLoader.load(floorTexture)
                                });
const frontWall = new THREE.Mesh(frontWallGeometry, frontWallMaterial);
frontWall.receiveShadow = true;
scene.add(frontWall);
frontWall.position.set(0,25,-50);

//Left Wall
const leftWallGeometry = new THREE.PlaneGeometry(100,50);
const leftWallMaterial = new THREE.MeshStandardMaterial(
                                {color:0x888888,
                                 side:THREE.DoubleSide,
                                map:textureLoader.load(floorTexture)
                                });
const leftWall = new THREE.Mesh(leftWallGeometry, leftWallMaterial);
leftWall.receiveShadow = true;
scene.add(leftWall);
leftWall.position.set(-50,25,0);
leftWall.rotation.y = -0.5*Math.PI;

//Right Wall
const rightWallGeometry = new THREE.PlaneGeometry(100,50);
const rightWallMaterial = new THREE.MeshStandardMaterial(
                                {color:0x888888,
                                 side:THREE.DoubleSide,
                                 map: textureLoader.load(floorTexture)
                                });
const rightWall = new THREE.Mesh(rightWallGeometry, rightWallMaterial);
rightWall.receiveShadow = true;
scene.add(rightWall);
rightWall.position.set(50,25,0);
rightWall.rotation.y = -0.5*Math.PI;

//Back wall
const backWallGeometry = new THREE.PlaneGeometry(100,50);
const backWallMaterial = new THREE.MeshStandardMaterial(
                                {color:0x999999,
                                 side:THREE.DoubleSide,
                                 map: textureLoader.load(floorTexture)
                                });
const backWall = new THREE.Mesh(backWallGeometry, backWallMaterial);
backWall.receiveShadow = true;
scene.add(backWall);
backWall.position.set(0,25,50);


//Lights ---------------------------------------( LIGHTS )----------------------------------------------------
//Ambient light
const ambientLight = new THREE.AmbientLight(0xffffff,2);
scene.add(ambientLight);
//Spotlight
const spotlight = new THREE.SpotLight(0xffffff,8888);
scene.add(spotlight);
spotlight.position.set(15,30,-15);
spotlight.castShadow = true;
spotlight.angle=1;
spotlight.penumbra=1;
//Spotlight 2
const spotlight2 = new THREE.SpotLight(0xffffff,9999);
scene.add(spotlight2);
spotlight2.position.set(-15,30,-15);
spotlight2.castShadow = true;
spotlight2.angle=1;
spotlight2.penumbra=1;



//GLTF -------------------------------------( GLTF )---------------------------------------------------
const modelLoader = new GLTFLoader();
 modelLoader.load(cactusURL.href, function(gltf){
    const model = gltf.scene;
   // scene.add(model);
    model.position.set(0,0,0);
    model.scale.set(10,10,10);
},undefined, function(error){
    console.log(error);
})



//GUI-------------------------------------------(GUI)------------------------------------------------------------
/*
const gui = new dat.GUI();
const options={
    visible:true,
    rotate:false,
    height:1,
    width:1,
    wood:false,
    brick:false,
   
}
gui.add(options,'visible').onChange(function(e){
    cube.material.visible=e;
});


gui.add(options,'rotate').onChange(function(e){
   e?rotation=true:rotation=false;
});
gui.add(options,'height',1,4).onChange(function(e){
    cube.scale.y=e;
    cube.position.y=e*4;
});
gui.add(options,'width',1,4).onChange(function(e){
    cube.scale.x=e;
});
gui.add(options,'wood').onChange(function(e){
    e?cube.material= woodMaterial:cube.material=cubeMaterial;
});
gui.add(options,'brick').onChange(function(e){
    e?cube.material= brickMaterial:cube.material=cubeMaterial;
});
*/

//Rotate cube
/*
let rotation = true;
function rotateCube(){
    if(rotation){
        cube.rotation.y += 0.01;
    }
}
*/

//RAY CASTING----------------------------------(RAY CASTING)--------------------------------------------------
//Ray caster
const mousePosition = new THREE.Vector2();

window.addEventListener('mousemove', function(e){
    mousePosition.x = (e.clientX / window.innerWidth)*2 - 1;
    mousePosition.y = -(e.clientY / window.innerHeight)*2 +1;
});

const rayCaster = new THREE.Raycaster();

cube2.name = 'concrete';
cube3.name = 'wood';
cube4.name = 'brick';
donut.name = 'donut';
donut2.name = 'donut2';

//------------------------------------------(ANIMATION)----------------------------------------------------
//Animation
//cm pos -18 50 45
camera.lookAt(0,0,0);
function animate(){
    //donut.visible=donut2.visible=cube.visible=plane2.visible=plane3.visible=plane4.visible=plane5.visible=false;
    donut.rotation.y +=0.02;
    donut2.rotation.y -=0.02;
   rayCaster.setFromCamera(mousePosition,camera);
   const intersects = rayCaster.intersectObjects(scene.children);
    //plane5.visible=false;
    if(camera.position.x > 50){
        camera.position.x -=0.2;
        camera.lookAt(0,5,0)
    }
    if(camera.position.x <-50){
      camera.position.x += 0.2;
      camera.lookAt(0,5,0)
    }
    if(camera.position.y > 100){
        camera.position.y-=0.2;
        camera.lookAt(0,5,0)
    }
    if(camera.position.y < 0){
      camera.position.y += 0.2;
      camera.lookAt(0,5,0)
    }
    if(camera.position.z > 50){
        camera.position.z-=0.2;
        camera.lookAt(0,5,0)
    }
    if(camera.position.z <-50){
      camera.position.z += 0.2;
      camera.lookAt(0,5,0)
    }
    for(let i =0;i<intersects.length;i++){
        if(intersects[i].object.name === 'concrete'){
            cube.material=concreteMaterial;
        }
        if(intersects[i].object.name === 'wood'){
            cube.material=woodMaterial;
        }
        if(intersects[i].object.name === 'brick'){
            cube.material=brickMaterial;
        }
        if(intersects[i].object.name === 'donut'){
            cube.rotation.y += 0.02;
           // cube.position.x +=0.1;
        }
        if(intersects[i].object.name === 'donut2'){
            cube.rotation.y -= 0.02;
            //cube.position.x -=0.1;
        }
        if(intersects[i].object.name==='sphere'){
            cube.geometry=sphereDemoGeometry;
        }
        if(intersects[i].object.name==='cylinder'){
            cube.geometry=demoCylinderGeometry;
        }
        if(intersects[i].object.name==='box'){
            cube.geometry=boxDemoGeometry;
        }
    }
   renderer.render(scene, camera);
}

//Animation loops
renderer.setAnimationLoop(animate);

//window resizing issue sol
window.addEventListener('resize',function(){
    camera.aspect = window.innerWidth/window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth,window.innerHeight);
})